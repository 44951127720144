



























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DeleteContentModal from '@/components/content/modal/DeleteContentModal.vue'
import InviteStudentsModal from '@/components/admin/modal/InviteUserModal.vue'
import { imagePreview } from '@/config/default/defaultImages'
import {
  lengthTitle,
  lengthDescription
} from '@/config/default/numberOfCharacters'
import { shorten } from '@/utils/formatters'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import ContentFactory from '@/factories/content'
import OrganizationsAPI from '@/api/requests/organizations'
import _ from 'lodash'
import { toastMapper } from '@/store/modules/toast'
import {
  organizationBadges,
  typeOrganization
} from '@/config/types/typeOrganization'
import { userMapper } from '@/store/modules/user'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import { dictMapper } from '@/store/modules/dicts'
import { inviteUserModal } from '@/config/types/inviteUserModal'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters([
      'userOrganizations',
      'isAuthenticated',
      'isAdmin'
    ]),
    ...mobileWidthMapper.mapState(['windowWidth'])
  },
  methods: {
    ...toastMapper.mapActions(['pushToast']),
    ...dictMapper.mapActions(['changeOrganizationId'])
  }
})

//TODO отображение dropDown-menu в карточке на разных экранах

@Component({
  components: {
    DeleteContentModal,
    InviteStudentsModal
  }
})
export default class OrganizationCardPreview extends Mapper {
  @Prop({ type: Object, default: ContentFactory.emptyOrganization() })
  organization!: any

  private organizationCopy: any
  private imagePreview_ = imagePreview
  private hover = false
  private canInviteStudents = false
  private canDeleteOrganization = false
  private canGoToOrganization = false
  private canGoToStudents = false
  private typeStudentsModal = inviteUserModal.inviteStudents

  private organizationBadges_ = organizationBadges
  private modalOrganization: any = null

  @Watch('windowWidth')
  private onChangeWindowWidth() {
    this.changeText()
  }

  private created() {
    this.organizationCopy = _.cloneDeep(this.organization)
    this.changeText()

    const ability = PermissionManager.common()
    const isOtherOrgType =
      this.organization.organizationType === typeOrganization['Другое']
    this.canInviteStudents =
      ability.can(permissions.inviteStudents, undefined) && !isOtherOrgType
    this.canDeleteOrganization = ability.can(
      permissions.deleteOrganization,
      undefined
    )
    this.canGoToOrganization = ability.can(
      permissions.goToOrganization,
      undefined
    )
    this.canGoToStudents =
      ability.can(permissions.goToStudents, undefined) && !isOtherOrgType
  }

  private changeText() {
    if (this.windowWidth >= 1200) {
      this.organization.shortDescription = shorten(
        this.organizationCopy.shortDescription,
        lengthDescription.large
      )
      this.organization.title = shorten(
        this.organizationCopy.title,
        lengthTitle.large
      )
      return
    }
    if (this.windowWidth >= 992) {
      this.organization.shortDescription = shorten(
        this.organizationCopy.shortDescription,
        lengthDescription.upperMedium
      )
      this.organization.title = shorten(
        this.organizationCopy.title,
        lengthTitle.medium
      )
      return
    }
    if (this.windowWidth >= 768) {
      this.organization.shortDescription = shorten(
        this.organizationCopy.shortDescription,
        lengthDescription.medium
      )
      this.organization.title = shorten(
        this.organizationCopy.title,
        lengthTitle.medium
      )
      return
    }

    this.organization.shortDescription = shorten(
      this.organizationCopy.shortDescription,
      lengthDescription.small
    )
    this.organization.title = shorten(
      this.organizationCopy.title,
      lengthTitle.small
    )
  }

  private isMyOrganization() {
    return _.find(this.userOrganizations, { id: this.organization.id })
  }
  private viewOrganizationButton() {
    this.$router.push(`/organizations/${this.organization.id}`)
  }
  private onDeleteClick() {
    this.$bvModal.show(`deleteModalId-${this.organization.id}`)
  }
  private onClickToStudents() {
    this.changeOrganizationId(this.organization.id)
    this.$router.push('/students')
  }
  private onStudentsInvite() {
    this.$bvModal.show(`inviteStudentsModalId-${this.organization.id}`)
    this.modalOrganization = this.organization
  }
  private async modalDelete() {
    const organizationId = parseInt(this.organization.id)
    if (!_.isNaN(organizationId)) {
      await OrganizationsAPI.deleteOrganization(organizationId)
        .then(response => {
          if (response.data) {
            this.$emit('deleteOrganization')
            this.pushToast({
              time: 5,
              title: 'Удаление',
              message: `Организация <strong>${this.organization.title}</strong> успешна удалена`
            })
          }
        })
        .catch(e => {
          this.pushToast({
            error: true,
            time: 5,
            title: 'Ошибка',
            message: 'Не удалось удалить данную организацию'
          })
          throw e
        })
    }
  }

  private onHover(hovered: boolean) {
    this.hover = hovered
  }
  private onPreviewClick() {
    this.$router.push(`/organizations/${this.organization.id}`)
  }
}
