export enum lengthTitle {
  'large' = 170,
  'medium' = 100,
  'small' = 80
}

export enum lengthDescription {
  'large' = 220,
  'upperMedium' = 100,
  'medium' = 60,
  'small' = 300
}
