





















































import { Vue, Component } from 'vue-property-decorator'
import OrganizationCardPreview from '@/components/content/OrganizationCardPreview.vue'
import { typeOrganization } from '@/config/types/typeOrganization'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { dictMapper } from '@/store/modules/dicts'
import { userMapper } from '@/store/modules/user'
import ExcelAPI from '@/api/requests/excel'

const Mapper = Vue.extend({
  computed: {
    ...dictMapper.mapGetters(['organizations']),
    ...userMapper.mapGetters([
      'isAdmin',
      'isAuthenticated',
      'isCurator',
      'isTeacher'
    ])
  },
  methods: {
    ...dictMapper.mapActions(['fetchAllOrganizations'])
  }
})

@Component({
  components: {
    OrganizationCardPreview
  }
})
export default class ListOrganizationsView extends Mapper {
  private filteredListOfOrganizations = [] as any[]
  private canInviteOrganization = false
  private valueOptions = 'Все'
  private filteredOptions = {
    title: null as string | null,
    type: null as string | null
  }
  private options = [
    { value: this.valueOptions, text: this.valueOptions },
    ...Object.values(typeOrganization).map((t: any) => ({
      value: t,
      text: t
    }))
  ]
  private selected = this.options[0].value

  private async created() {
    await this.fetchAllOrganizations()
    this.filteredListOfOrganizations = this.organizations
    const ability = PermissionManager.common()
    this.canInviteOrganization = ability.can(
      permissions.inviteOrganization,
      undefined
    )
  }
  private filterOrganizations() {
    if (this.filteredOptions.type != 'Все') {
      this.filteredListOfOrganizations = this.organizations.filter(
        (org: any) =>
          (!this.filteredOptions.title ||
            org.title.includes(this.filteredOptions.title)) &&
          (!this.filteredOptions.type ||
            org.organizationType === this.filteredOptions.type)
      )
    } else {
      this.filteredListOfOrganizations = this.organizations.filter(
        (org: any) =>
          !this.filteredOptions.title ||
          org.title.includes(this.filteredOptions.title)
      )
    }
  }
  private async changeFilterOrganization(e: string) {
    this.filteredOptions.type = e
    this.filterOrganizations()
  }
  private async onSearchName(e: string) {
    this.filteredOptions.title = e
    this.filterOrganizations()
  }
  private onAddOrganizationClick() {
    this.$router.push('/organizations/new')
  }
  private async deleteOrganization() {
    await this.fetchAllOrganizations()
    this.filteredListOfOrganizations = this.organizations
  }

  private async exportAllResults() {
    await ExcelAPI.getAllResults()
  }
}
